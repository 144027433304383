import {
  WALLET_DEPOSIT,
  WALLET_MIGRATE,
  WALLET_SWAP_PRIORITY,
  WALLET_TOPUP_HISTORY,
} from './../../utils/network/ApiEndpoints';
//REDUX-TOOLKIT
import {
  setAllAddresses,
  setAllNetworks,
  setAllTokens,
  setAssets,
  setGrowth,
  setMigrationHistory,
  setTopupHistory,
  setTransactionHistory,
  setWallet,
  setWalletPrioritySetting,
  setWithdrawHistory,
} from './walletsSlice';
import { useDispatch } from 'react-redux';
//UTILS
import {
  ALL_NETWORKS,
  ALL_TOKENS,
  POST_SWAP,
  POST_WITHDRAW,
  TRANSACTION_HISTORY,
  MIGRATION_HISTORY,
  WALLET_ASSETS,
  WALLET_GROWTH,
  WALLET_OVERVIEW,
  WITHDRAW_ADDRESS,
  WITHDRAW_HISTORY,
  WITHDRAW_PAYMENT_LINK,
  WALLET_TOKEN_PAIR,
} from '../../utils/network/ApiEndpoints';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';

export const useWalletActions = () => {
  const dispatch = useDispatch();

  const walletOverview = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_OVERVIEW,
      })
      .then((response: any) => {
        dispatch(setWallet(response.data.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const walletAssets = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_ASSETS,
      })
      .then((response: any) => {
        dispatch(setAssets(response.data.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const walletGrowth = async (params: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_GROWTH,
        params,
      })
      .then((response: any) => {
        dispatch(setGrowth(response.data.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const getWithdrawHistory = async (params: any, saveRedux = true) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WITHDRAW_HISTORY,
        params,
      })
      .then((response: any) => {
        if (saveRedux) dispatch(setWithdrawHistory(response.data.data));
        return response.data;
      })
      .catch((error: any) => error);
  };

  const getTransactionHistory = async (params: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: TRANSACTION_HISTORY,
        params,
      })
      .then((response: any) => {
        dispatch(setTransactionHistory(response.data.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const getMigrationHistory = async (params?: any) => {
    const req = params
      ? {
          method: 'GET',
          url: MIGRATION_HISTORY,
          params,
        }
      : {
          method: 'GET',
          url: MIGRATION_HISTORY,
        };
    return await ApiRequestHomnifi()
      .request(req)
      .then((response: any) => {
        dispatch(setMigrationHistory(response.data.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const getAllTokens = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: ALL_TOKENS,
      })
      .then((response: any) => {
        dispatch(setAllTokens(response.data.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const getAllNetworks = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: ALL_NETWORKS,
      })
      .then((response: any) => {
        dispatch(setAllNetworks(response.data.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const postWithdraw = async (data: {
    fromWallet: string;
    toAddress: string;
    amount: number;
    network: string;
    otp: any;
  }) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: POST_WITHDRAW,
        data: data,
      })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => error);
  };

  const postSwap = async (data: {
    fromWallet: string;
    toWallet: string;
    amount: number;
  }) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: POST_SWAP,
        data: data,
      })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => error);
  };

  const swapPriority = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_SWAP_PRIORITY,
      })
      .then((response: any) => {
        dispatch(setWalletPrioritySetting(response.data.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const getWithdrawAddresses = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WITHDRAW_ADDRESS,
      })
      .then((response: any) => {
        dispatch(setAllAddresses(response.data.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const getWithdrawUnlockPaymentLink = async (params: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WITHDRAW_PAYMENT_LINK,
        params,
      })
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return error;
      });
  };

  const getWalletTokenPair = async (swapFrom: string, swapTo: string) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_TOKEN_PAIR + '/' + swapFrom + '/' + swapTo,
      })
      .then((response: any) => response?.data?.data)
      .catch((error: any) => error);
  };

  const walletMigrate = async (data: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: WALLET_MIGRATE,
        data,
      })
      .then((response: any) => response)
      .catch((error: any) => error);
  };

  const walletDeposit = async (amount: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: WALLET_DEPOSIT,
        data: { amount: Number(amount) },
      })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => error);
  };

  const walletTopupHistory = async (filters: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_TOPUP_HISTORY,
        params: filters,
      })
      .then((response: any) => {
        dispatch(setTopupHistory(response?.data?.data));
        return response?.data;
      })
      .catch((error: any) => error);
  };

  return {
    walletOverview,
    walletAssets,
    walletGrowth,
    getWithdrawHistory,
    getAllTokens,
    getAllNetworks,
    postWithdraw,
    postSwap,
    swapPriority,
    getWithdrawAddresses,
    getWithdrawUnlockPaymentLink,
    getTransactionHistory,
    getMigrationHistory,
    getWalletTokenPair,
    walletMigrate,
    walletDeposit,
    walletTopupHistory,
  };
};
