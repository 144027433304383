import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

type Props = {
  value: number;
  isBlackThemed?: boolean;
};

const LinearProgressBar = ({ value, isBlackThemed = false }: Props) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: isBlackThemed
        ? ' #D9D9D9'
        : theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: isBlackThemed
        ? '#0E173F'
        : value < 20
          ? '#F23838'
          : value > 50
            ? '#5BAC0A'
            : '#308fe8',
    },
  }));

  return (
    <BorderLinearProgress
      variant="determinate"
      value={value > 100 ? 100 : value}
    />
  );
};

export default LinearProgressBar;
