import {
  AppsIcon,
  ArrowRightIcon,
  CloudIcon,
  CommunityIcon,
  DasboardIcon,
  EmptyWalletIcon,
  GlobalPoolIcon,
  QuantwiseIcon,
  RampSidebarIcon,
  ShopIcon,
  WallekIcon,
  XeraSidebarIcon,
} from '../images/svgs';
import {
  CLOUDK_CONSTANT,
  QUANTWISE_CONSTANT,
  RAMPSTARTER_CONSTANT,
  // RAMPSTARTER_CONSTANT_2,
  SHOP_CONSTANT,
  WALLEK_CONSTANT,
  XERA_CONSTANT,
} from './constants';

export const SidebarData = [
  {
    StartIcon: DasboardIcon,
    EndIcon: '',
    text: 'Home',
    key_value: '',
    url: '/',
    children: null,
  },
  {
    StartIcon: EmptyWalletIcon,
    EndIcon: ArrowRightIcon,
    text: 'Wallet',
    url: '/wallet',
    key_value: 'wallet',
    children: [
      {
        text: 'Deposit',
        url: '/wallet/deposit',
        key_value: 'deposit',
      },
      {
        text: 'Withdraw',
        url: '/wallet/withdraw',
        key_value: 'withdraw',
      },

      {
        text: 'Swap',
        url: '/wallet/swap',
        key_value: 'swap',
      },
      {
        text: 'History',
        url: '/wallet/history',
        key_value: 'history',
      },
    ],
  },
  {
    StartIcon: CloudIcon,
    EndIcon: ArrowRightIcon,
    text: 'Defi 360',
    url: '/defi-360',
    key_value: 'defi-360',
    children: [
      {
        text: 'NodeK',
        url: '/defi-360/nodek',
        key_value: 'nodek',
      },
      {
        text: 'My CloudK',
        key_value: 'cloudK-1.0',
        external: true,
        externalId: CLOUDK_CONSTANT.externalId,
      },
    ],
  },
  {
    StartIcon: CommunityIcon,
    EndIcon: ArrowRightIcon,
    text: 'My Community',
    url: '/community/super-node',
    key_value: 'community',
    children: [
      // {
      //   text: 'My Friends',
      //   url: PLATFORM_ENVIRONMENT === 'DEV' ? '/community/friends': '/community',
      //   key_value: 'friends',
      // },
      {
        text: 'Super Node',
        url: '/community/super-node',
        key_value: 'superNode',
      },
    ],
  },
  // Uncomment to enable Global Pools
  // {
  //   StartIcon: GlobalPoolIcon,
  //   EndIcon: ArrowRightIcon,
  //   text: 'Global Pool',
  //   url: '/global-pool/sngp',
  //   key_value: 'global-pool',
  //   children: [
  //     {
  //       text: 'SNGP',
  //       url: '/global-pool/sngp',
  //       key_value: 'sngp',
  //     },
  //     {
  //       text: 'Country Pools',
  //       url: '/global-pool/country-pools',
  //       key_value: 'country-pools',
  //     },
  //   ],
  // },
  {
    StartIcon: AppsIcon,
    EndIcon: '',
    text: 'External Apps',
    url: '/external-apps',
    key_value: 'external-apps',
    children: null,
  },
  {
    // Divider entry
    text: 'Quick Links',
    isDivider: true, // Flag to identify this entry as a divider
  },
  {
    StartIcon: ShopIcon,
    EndIcon: '',
    text: 'Shop',
    url: '',
    external: true,
    children: null,
    externalId: SHOP_CONSTANT.externalId,
    key_value: 'shop',
  },
  {
    StartIcon: QuantwiseIcon,
    EndIcon: '',
    text: 'Quantwise',
    key_value: 'quantwise',
    url: '',
    external: true,
    children: null,
    externalId: QUANTWISE_CONSTANT.externalId,
  },
  {
    StartIcon: WallekIcon,
    EndIcon: '',
    text: 'WalleK',
    key_value: 'walleK',
    url: '',
    children: null,
    external: true,
    externalId: WALLEK_CONSTANT.externalId,
  },
  {
    StartIcon: RampSidebarIcon,
    EndIcon: '',
    text: 'Rampstarter',
    url: '',
    external: true,
    children: null,
    externalId: RAMPSTARTER_CONSTANT.externalId,
    key_value: 'rampstarter',
  },
  // {
  //   StartIcon: RampSidebarIcon,
  //   EndIcon: '',
  //   text: 'Rampstarter 2.0',
  //   url: '',
  //   external: true,
  //   children: null,
  //   externalId: RAMPSTARTER_CONSTANT_2.externalId,
  //   key_value: 'rampstarter2',
  // },
  {
    StartIcon: XeraSidebarIcon,
    EndIcon: '',
    text: 'My Xera Pro',
    url: XERA_CONSTANT.externalId,
    external: false,
    children: null,
    externalId: '',
    key_value: 'myxera',
  },
];
