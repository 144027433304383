//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TMACHINE_DATA,
  TUserProducts,
  TUserTransactionHistory,
} from '../../data/cloudk2';
import { TUserRewards } from '../../types/cloundk.type';

export interface Clouldk2State {
  purchaseCalculatorAutoCompounding: boolean;
  purchaseCalculatorValue: number;

  activeMachines: TUserProducts[];
  machineList: TMACHINE_DATA[];
  userProducts?: TUserProducts[];
  userRewards: TUserRewards;
  globalAutoCompound: boolean;
  userTransactionHistory: TUserTransactionHistory[];

  userRewardsLoading: boolean;
  userMachinesLoading: boolean;
  userMachineByIdLoading: boolean;
  userGlobalAutoCompoundLoading: boolean;
  userGlobalClaimRewardsLoading: boolean;
  setGlobalMachineAutoCompoundLoading: boolean;
  userGlobalMachineRewardsLoading: boolean;
  userTransactionHistoryLoading: boolean;
  postStakeIntoMachineLoading: boolean;
  purchaseCalculatorAdvancedOption: boolean;
  purchaseCalculatorAth: string;
  purchaseCalculatorDlp: string;

  getAllProductsLoading: boolean;

  // stack more
  stackMoreToken?: string;
  stackMoreTokenLoading: boolean;
  stackMoreAmount: number;
  stackMorePercentage: number;
}

export const CLOULDK2_SLICE_NAME = 'clouldk2';

const initialState: Clouldk2State = {
  purchaseCalculatorAutoCompounding: true,
  purchaseCalculatorValue: 500,
  purchaseCalculatorAdvancedOption: false,

  activeMachines: [],
  machineList: [],
  userRewards: {},
  globalAutoCompound: false,
  userTransactionHistory: [],

  userRewardsLoading: true,
  userMachinesLoading: false,
  userMachineByIdLoading: false,
  userGlobalAutoCompoundLoading: false,
  userGlobalClaimRewardsLoading: false,
  setGlobalMachineAutoCompoundLoading: false,
  userGlobalMachineRewardsLoading: false,
  userTransactionHistoryLoading: false,
  postStakeIntoMachineLoading: false,
  purchaseCalculatorAth: '',
  purchaseCalculatorDlp: '',
  getAllProductsLoading: false,

  // stack more
  stackMoreTokenLoading: false,
  stackMoreAmount: 0,
  stackMorePercentage: 0,
};

const cloudk2Slice = createSlice({
  name: CLOULDK2_SLICE_NAME,
  initialState,
  reducers: {
    setClouldk2Slice: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },

    setMoreStackPercentage: (state, action: PayloadAction<any>) => {
      const { percentage, walletList } = action.payload;

      if (!percentage && !walletList) {
        return {
          ...state,
          stackMorePercentage: 0,
        };
      }

      state.stackMorePercentage = percentage;

      const balance = walletList.total || 0;

      const amount = balance * (state.stackMorePercentage / 100);
      state.stackMoreAmount = amount;
    },

    setMoreStackPercentageToken: (state, action: PayloadAction<any>) => {
      const { percentage, stakingToken } = action.payload;

      if (!percentage && !stakingToken) {
        return {
          ...state,
          stackMorePercentage: 0,
        };
      }

      state.stackMorePercentage = percentage;

      const balance = stakingToken?.balance;

      const amount = balance * (state.stackMorePercentage / 100);
      state.stackMoreAmount = amount;
    },

    setStackMoreAmount: (state, action: PayloadAction<any>) => {
      if (!action.payload) {
        return {
          ...state,
          stackMoreAmount: 0,
        };
      }

      state.stackMoreAmount = action.payload;
      state.stackMorePercentage = 0;
    },

    updateMachine: (state, action: PayloadAction<any>) => {
      const { id, data } = action.payload;

      const tempState = state;

      const machine = tempState.machineList.map((_machine: any) =>
        _machine._id === id
          ? {
              ..._machine,
              ...data,
            }
          : _machine
      );

      return { ...state, machineList: [...machine] };
    },
  },
});

export const clouldk2SliceAction = cloudk2Slice.actions;
export const {
  setClouldk2Slice,
  updateMachine,
  setMoreStackPercentage,
  setStackMoreAmount,
  setMoreStackPercentageToken,
} = cloudk2Slice.actions;
export const selectCloudSlice = (state: {
  [CLOULDK2_SLICE_NAME]: Clouldk2State;
}) => state[CLOULDK2_SLICE_NAME];

// select single machine
export const selectCloudMachine = (
  state: {
    [CLOULDK2_SLICE_NAME]: Clouldk2State;
  },
  id: string
) =>
  state[CLOULDK2_SLICE_NAME].machineList.find((machine) => machine._id === id);

export default cloudk2Slice.reducer;
