// superNodeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISuperNodeState } from '../../types/superNodes.type';


const initialState: ISuperNodeState = {
  getTotalRewardsLoading: false,
  totalRewards: null,
  rewardChart: [],
  rewardTableData: [],
  getMyFriendsHistoryLoading: false,
  claimRewardLoading: false,
  rewardTreeLoading: false,
  getMyTreeLoading: false,
  myTree: [],
  getProductionLoading: false,
  getSuperNodeRewardLoading: false,
  detailedUser: {
    _id: '',
    blockchainId: '',
    createdAt: '',
    email: '',
    firstName: '',
    lastName: '',
    profilePicture: '',
    updatedAt: '',
    username: '',
    dateJoined: '',
  },
  superNodeProductData: {
    myProduction: 0,
    teamProduction: 0,
    firstLineProduction: 0,
    netTotal: 0,
    token:0,
  },
  myTreeData: {
    firstLineNode: 0,
    totalNodes: 0,
    activeFirstLineNode: 0,
    activeTotalNode: 0,
  },
  getCurrentLossOfRewardsLoading: false,
  currentLossOfRewardsLoss: {
    inActiveFirstUser: 0,
    dailyCapping: 0,
    insufficientGask: 0,
    netTotal: 0,
  },
  getRewardAnalyticsLoading: false,
  getSngpScoreLoading: false,
  getUserDailyRewardLoading: false,
  getUserChartDetailLoading: false,
  getUserRewardHistoryLoading: false,
  userRewardHistory: {
    currentPage: 1,
    list: [],
    totalCount: 0,
    totalPages: 0,
  },
  userChartDetail: [
    {
      x: '',
      y: null,
    },
  ],
  userDailyReward: {
    totalReward: 0,
    dailyRewards: 0,
    dailyProduction: 0,
    totalProduction: 0,
    token: {
      _id: '',
      name: '',
      symbol: '',
      type: '',
      withdrawType: '',
      color: '',
      networks: [],
      iconUrl: '',
    },
  },
  superNodeRewardsAnalytics: {
    totalRewards: 0,
    baseReferralRewards: 0,
    builderGenerationalRewards: 0,
    builderReferralRewards:0,
    claimableRewards: 0,
    rewardToken: {
      _id: '',
      name: '',
      symbol: '',
      type: '',
      withdrawType: '',
      color: '',
      networks: [],
      iconUrl: '',
    },
  },
  userActiveStatus: {
    isActive: null,
  },
  getTotalGaskLoading: false,
  totalGask: 0,
  sngpScoreResponse: [],
  getDailySuperNodeRewardsLoading: false,
  dailySuperNodeRewards: {
    totalToken: 0,
    tokenAmount: 0,
    rewardToken: {
      _id: '',
      name: '',
      symbol: '',
      type: '',
      withdrawType: '',
      color: '',
      networks: [],
      iconUrl: '',
    },
  },
  getActiveFirstLineUsersLoading: false,
  activeFirstLineUsers: [],
  superNodeSuperNodeRewardsLoading: false,
  superNodeSuperNodeRewards: {
    currentPage: 0,
    list: [],
    totalCount: 0,
    totalPages: 0,
  },
  // getActiveFirstLineUsersLoading:false
};

export const SUPERNODE_SLICE_NAME = 'superNode';

const superNodeSlice = createSlice({
  name: SUPERNODE_SLICE_NAME,
  initialState,
  reducers: {
    setSuperNodeSlice: (
      state,
      action: PayloadAction<Partial<ISuperNodeState>>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSuperNodeSlice } = superNodeSlice.actions;
export const selectSuperNodeSlice = (state: {[SUPERNODE_SLICE_NAME]: ISuperNodeState}) => state[SUPERNODE_SLICE_NAME];
export default superNodeSlice.reducer;
