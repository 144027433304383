import React from 'react';
import GlobalModal from '../Global/GlobalModal';
import useUserCheck from '../../hooks/useUserCheck';
import { profileImg } from '../../images';
import QRCode from 'react-qr-code';
import { CopiedIcon, CopyIcon } from '../../images/svgs';
import {
  DASHBOARD_REFERRAL,
  PLATFORM_NAME,
  SOCIALS,
} from '../../utils/constants';
import { toast } from 'react-toastify';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from 'react-share';

const ProfileQR = ({
  businessCard,
  setBusinessCard,
}: {
  businessCard: boolean;
  setBusinessCard: (e: boolean) => void;
}) => {
  const { user } = useUserCheck();
  const [copied, setIsCopied] = React.useState(false);

  const referral = `${DASHBOARD_REFERRAL}${user?.referralCode}`;

  const writeClipboardText = async (text: string) => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(text);
      } else {
        fallbackWriteClipboardText(text);
      }
    } catch (error) {
      fallbackWriteClipboardText(text);
    }
  };

  const fallbackWriteClipboardText = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999); // For mobile devices

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error('Fallback copy to clipboard failed: ', error);
    }

    document.body.removeChild(textArea);
  };

  const handleCopy = () => {
    writeClipboardText(referral || '');
    toast.success('Link copied!');
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);

    // navigator.clipboard.writeText(referral || '').then(() => {
    //   toast.success('Link copied!');
    //   setIsCopied(true);
    //   setTimeout(() => {
    //     setIsCopied(false);
    //   }, 5000);
    // });
  };

  const renderShareButton = (social: any) => {
    const handleShare = async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: PLATFORM_NAME,
            text: `Check out ${PLATFORM_NAME}`,
            url: referral,
          });
        } catch (error) {
          console.error('Error sharing content:', error);
        }
      } else {
        console.error('Web Share API is not supported in this browser.');
      }
    };
    switch (social.title) {
      case 'Facebook':
        return (
          <FacebookShareButton
            url={referral}
            title={`Check out ${PLATFORM_NAME || ''}!`}
          >
            <img src={social.icon} alt={social.title} className="social-icon" />
          </FacebookShareButton>
        );
      case 'Linkedin':
        return (
          <LinkedinShareButton
            url={referral}
            title={`Join ${PLATFORM_NAME || ''}!`}
          >
            <img src={social.icon} alt={social.title} className="social-icon" />
          </LinkedinShareButton>
        );
      case 'TwitterX':
        return (
          <TwitterShareButton
            url={referral}
            title={`Check out ${PLATFORM_NAME || ''}!`}
          >
            <img src={social.icon} alt={social.title} className="social-icon" />
          </TwitterShareButton>
        );
      case 'Telegram':
        return (
          <TelegramShareButton
            url={referral}
            title={`Check out ${PLATFORM_NAME || ''}!`}
          >
            <img src={social.icon} alt={social.title} className="social-icon" />
          </TelegramShareButton>
        );
      case 'Instagram':
        return (
          <a href={social.link} target="_blank" rel="noopener noreferrer">
            <img src={social.icon} alt={social.title} className="social-icon" />
          </a>
        );
      case 'share':
        return (
          <button onClick={handleShare} className="social-icon-button">
            <img src={social.icon} alt={social.title} className="social-icon" />
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <GlobalModal
      isOpen={businessCard}
      onClose={() => setBusinessCard(false)}
      title="Share referral & get benefit!"
      childrenClass="p-0"
      size="lg"
    >
      <div className="w-full bg-gradient-to-tr from-primary to-primaryDark px-10 lg:px-20 py-10 rounded-b-xl">
        <div className="w-full flex flex-col pt-6">
          <div className="flex flex-col gap-2 text-white backdrop-lg bg-white/10 p-4 rounded-t-xl">
            <div className="w-full flex justify-center">
              <div className="w-20 h-20 rounded-full overflow-hidden -mt-14">
                <img
                  src={user?.profilePicture ? user?.profilePicture : profileImg}
                  alt="Profile"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div>
              Join Me on {PLATFORM_NAME}!
              {/* <span className="font-medium">@{user?.username}!</span> */}
            </div>
            <span className="font-light text-sm">
              Unlock the future of Web3 and decentralized finance with{' '}
              {PLATFORM_NAME}.
              <br />
              Use my referral code{' '}
              <span className="font-medium">{user?.referralCode}</span> to join
              and start exploring the platform today!
            </span>
          </div>
          <div className="flex flex-col gap-4 backdrop-lg bg-white p-4 rounded-b-lg">
            <div className="flex w-full justify-center py-2">
              <QRCode
                className="w-28 h-28"
                value={referral}
                viewBox={`0 0 256 256`}
              />
            </div>

            <span className="font-medium">Share with a friend</span>
            <div className="grid grid-cols-5 gap-2 place-items-center">
              {SOCIALS.map((social) => (
                <div key={social.title}>
                  <div
                    className={`sm:col-span-1 col-span-2 place-items-center w-10 h-10 rounded-full overflow-hidden p-2 ${social.color}`}
                  >
                    {renderShareButton(social)}
                  </div>
                </div>
              ))}
            </div>
            <span className="font-medium">Copy Referral Link</span>

            <div className="relative flex items-center">
              <input
                type="text"
                className="w-full rounded-lg border border-borderBlue bg-gray-100 text-sm px-3 py-2 text-primaryGrey"
                value={referral}
                disabled
              />
              <button
                className="absolute right-4 bg-gray-100 pl-2"
                onClick={handleCopy}
              >
                {copied ? <CopiedIcon /> : <CopyIcon />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </GlobalModal>
  );
};

export default ProfileQR;
