import React from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  notificationCount?: number;
  active?: boolean;
  status?: boolean | null; 
  className?: string;
};

const GlobalIconWrap = ({
  children,
  notificationCount,
  active,
  status = null,
  className = '',
}: Props) => {
  const location = useLocation();

  function isBoolean(value:any) {
    return typeof value === 'boolean';
  }
  return (
    <div
      className={`relative flex items-center justify-center ${className} border p-3 rounded-full hover:text-primary hover:border-primary/80 ${active ? 'text-primary border-primary/80' : 'text-defaultBlack border-borderBlue'} transition ease-in-out`}
    >
      {children}
      {/* Status Indicator */}
      {isBoolean(status) && location.pathname === '/community/super-node' && (
        <span className="absolute -top-[2px] right-[5px] flex gap-2 h-2 w-2">
          <span
            className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${
              status ? 'bg-primaryGreen' : 'bg-primaryRed'
            }`}
          ></span>
          <span
            className={`relative inline-flex rounded-full h-2 w-2 ${
              status ? 'bg-primaryGreen' : 'bg-primaryRed'
            }`}
          ></span>
        </span>
      )}
      {notificationCount ? (
        <span className="absolute -top-1 -right-1 flex items-center justify-center bg-redSecondary rounded-full w-5 h-5 text-white font-medium text-xs">
          {notificationCount}
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

export default GlobalIconWrap;
