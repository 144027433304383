import { parseISO, isAfter } from 'date-fns';
import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const formatNumberWithComma = (num: number, decimal = 8) => {
  return num?.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
export const formatNumberWithCommas = (num: number) => {
  if (num === undefined || num === null) {
    return ''; // Return an empty string or a default value as needed
  }
  const numStr = num.toString();
  const [integerPart, decimalPart] = numStr.split('.');
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
};

// numberFormat
// european style number format with comma and dot (ex: 1.000,00)
export const numberFormat = (num: number = 0, isHide = false) => {
  const value = num
    ?.toFixed(8)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  if (isHide) return hideNumber(value);
  return value;
};

export const hideNumber = (value: string) => {
  var total = value.replace(/\d/g, '*');
  return total;
};

export const generateRandomString = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export function extractNumbers(inputArray: any) {
  const resultArray = inputArray.map((item: any) => {
    const match = item.match(/€(\d+)/);
    return match ? Number(match[1]) : null;
  });
  return resultArray;
  /* if array ["LlvUDVbNzB2 - €23", "HC0qdQNmZKh - €23"] then result ["23", "23"] */
}

export function extractAlphanumeric(inputArray: any) {
  const resultArray = inputArray.map((item: any) => {
    const match = item.match(/([A-Za-z0-9]+) -/);
    return match ? match[1] : null;
  });
  return resultArray;
  /* if array ["LlvUDVbNzB2 - €23", "HC0qdQNmZKh - €23"] then result ["LlvUDVbNzB2", "HC0qdQNmZKh"] */
}

export function formatDateToYYYYMMDD(dateObj: any) {
  if (
    dateObj &&
    dateObj.$y !== undefined &&
    dateObj.$M !== undefined &&
    dateObj.$D !== undefined
  ) {
    const year = dateObj.$y;
    const month = String(dateObj.$M + 1).padStart(2, '0'); // Month is zero-based
    const day = String(dateObj.$D).padStart(2, '0');

    return `${year}-${month}-${day}`;
  } else {
    console.error('Invalid date object:', dateObj);
    // Handle the invalid date object as needed
    return null; // or throw an error
  }
}

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const otpValidaty = (vCode: Array<string>) => {
  const otp = vCode.join('') ? vCode.join('') : null;
  if (!otp || otp.length < 6 || otp.length > 6) {
    return 'Invalid OTP length';
  }
};

export const formatAmount = (amount: any) => {
  if (typeof amount === 'string') {
    return Number(amount)?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else if (typeof amount === 'number') {
    return amount?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return amount;
  }
};

export const getTableIndexNumber = ({
  index,
  limit,
  page,
}: {
  page: number;
  index: number;
  limit: number;
}) => {
  return (page - 1) * limit + (index + 1);
};

// export const getDaysInMonth = (month: number, year: number): number => {
//   return new Date(year, month, 0).getDate();
// };
export const getDaysInMonth = (month: number, year?: number): number => {
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  return daysInMonth[month - 1];
};

export function isDateExpired(dateString: string) {
  const dateObject = parseISO(dateString);
  const currentDate = new Date();

  return isAfter(currentDate, dateObject);
}
export function toFixedWithoutRoundingOff(value: number, decimal: number) {
  const fixedNum = Math.floor(value * 1000000) / 1000000;
  const val = fixedNum.toFixed(decimal);
  return val;
}
export enum AmountType {
  DOLLAR = 'dollar',
  TOKEN = 'token',
}

export const setDecimalPlaces = (
  value: number,
  decimalPlacesType: AmountType
) => {
  const decimalPlaces = decimalPlacesType === AmountType.DOLLAR ? 2 : 8;
  const factor = Math.pow(10, decimalPlaces);
  return (
    (value >= 0 ? Math.floor(value * factor) : Math.ceil(value * factor)) /
    factor
  );
};
// For notification time listing
export function timeAgo(timestamp: string): string {
  const now = new Date();
  const time = new Date(timestamp);
  const secondsAgo = Math.floor((now.getTime() - time.getTime()) / 1000);

  if (secondsAgo < 60) {
    return 'Now';
  } else if (secondsAgo < 3600) {
    const minutes = Math.floor(secondsAgo / 60);
    return `${minutes}m`;
  } else if (secondsAgo < 86400) {
    const hours = Math.floor(secondsAgo / 3600);
    return `${hours}h`;
  } else if (secondsAgo < 604800) {
    const days = Math.floor(secondsAgo / 86400);
    return `${days}d`;
  } else if (secondsAgo < 2592000) {
    const weeks = Math.floor(secondsAgo / 604800);
    return `${weeks}w`;
  } else if (secondsAgo < 31536000) {
    const months = Math.floor(secondsAgo / 2592000);
    return `${months}m`;
  } else {
    const years = Math.floor(secondsAgo / 31536000);
    return `${years}y`;
  }
}

export const capitalizeFirstLetter = (label: string): string => {
  return label.charAt(0).toUpperCase() + label.slice(1);
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
