import { useDispatch } from 'react-redux';
//API
import { useNavigate } from 'react-router-dom';
import {
  COUNTRIES,
  GET_SUPPORT,
  MAINTENANCE,
} from '../../utils/network/ApiEndpoints';
import { toast } from 'react-toastify';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import { ApiResponse } from '../../components/interface/AxiosResponse.interface';
import axios from 'axios';
import ApiRequestBID from '../../utils/network/ApiRequestBID';
import { TCountryResponse } from '../../components/types/general/general.types';
import { setConvertedPrice, setCountry, setMaintenance } from './generalSlice';
import { rewardsSliceAction } from '../Cloudk2/rewardCalculatorSlice';
import { getLoginToken } from '../../utils/storage/protectedStorage';

export const useGeneralActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginToken = getLoginToken();

  const getCountries = async () => {
    return await ApiRequestBID()
      .request<void, ApiResponse<TCountryResponse>>({
        method: 'GET',
        url: COUNTRIES,
      })
      .then((response) => {
        const { data } = response;
        dispatch(setCountry(data));
        return response;
      })
      .catch((error: any) => {
        toast.error(error);
        return error;
      });
  };
  //this is not homnifi notifications
  // const getNotifications = async () => {
  //   return await ApiRequestBID()
  //     .request({
  //       method: 'GET',
  //       url: READ_NOTIFICATIONS,
  //     })
  //     .then((response) => {
  //       const { data } = response;
  //       return data;
  //     })
  //     .catch((error: any) => {
  //       return error;
  //     });
  // };
  const getSupports = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: GET_SUPPORT,
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error: any) => {
        return error;
      });
  };
  const GetConvertedPrice = async () => {
    return await axios
      .request({
        method: 'GET',
        url: `https://openapi.koinbay.com/sapi/v1/ticker?symbol=LYKUSDT`,
      })
      .then((response: any) => {
        dispatch(setConvertedPrice(response.data.sell));
        dispatch(
          rewardsSliceAction.setRewardSlice({
            LYK: response.data.sell,
          })
        );
        dispatch(rewardsSliceAction.initRewardCalculator());

        return response;
      })
      .catch((error: any) => error);
  };

  const getMaintenance = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: MAINTENANCE,
      })
      .then((response: any) => {
        if (!response.data.data) {
          navigate('/');
        } else {
          dispatch(setMaintenance(response.data.data));
        }
        return response;
      })
      .catch((error: any) => error);
  };

  const checkUserMembership = async ({ bid }: { bid: string }) => {
    return await axios
      .request({
        method: 'GET',
        url: `https://api-qa-app.kmall.io/partner/users/${bid}/licenses/count/`,
        headers: {
          Authorization: `Token ${loginToken ? loginToken : null}`,
          'x-api-key':
            'e23G5bwqYdQoV6ZXcthr06RdvJYWeXoXAXTwABiaD6yhWxyo9WP1WIA3qGXCwiE1ZfAJdPavakmA',
          platform: 'CUBE_WALLET',
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.data.message);

        return error;
      });
  };

  return {
    getCountries,
    checkUserMembership,
    GetConvertedPrice,
    getSupports,
    getMaintenance,
  };
};
