import { Suspense } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import AuthLayout from '../components/Auth/AuthLayout';
import VerificationLayout from '../components/Auth/VerificationLayout';
import RewardsCalculator from '../components/Cloud/RewardsCalculator';
import PreLoader from '../components/Global/PreLoader';
import Layout from '../components/Layout';
import {
  AccountType,
  Cloud2,
  Cloudk2PurchasePage,
  CountryPools,
  Dashboard,
  Defi,
  EmailSent,
  ExternalApp,
  ForgetPassword,
  GoogleAuth,
  Legal,
  Login,
  Maintenance,
  MyFriendsPage,
  Newsletter,
  Notifiations,
  PageNotFound,
  Profile,
  ReferralLink,
  ResetPassword,
  Signup,
  SSOAuth,
  Success,
  SuperNode,
  Support,
  Terms,
  VerifyEmail,
  Wallet,
  Walletk,
  WalletNew,
  WalletsDeposit,
  WalletsHistory,
  WalletsSwap,
  WalletsWithdraw,
  Wiki,
  Xera,
  SNGP,
} from '../pages';
import Impersonate from '../pages/Auth/Impersonate';
import Community from '../pages/Community';

export enum PAGES {
  LOGIN = '/auth/signin',
  DASHBOARD = '/',
  GOOGLE_AUTH = '/verification/google-authentication',
  VERIFY_EMAIL = '/auth/verify-account',
  REFERRAL_LINK = '/verification/referral-link',
  TERMS = '/verification/terms',
  ACCOUNT_TYPE = '/verification/account-type',
  REWARDS_CALCULATOR = '/defi-360/nodek/rewards-calculator',
}

export const router = createBrowserRouter([
  {
    path: '/auth',
    element: (
      <Suspense fallback={<PreLoader />}>
        <AuthLayout />
      </Suspense>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="signin" replace />,
      },
      {
        path: 'signup',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Signup />
          </Suspense>
        ),
      },
      {
        path: 'signup-success',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Success />
          </Suspense>
        ),
      },
      {
        path: 'signin',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Login />
          </Suspense>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <Suspense fallback={<PreLoader />}>
            <ForgetPassword />
          </Suspense>
        ),
      },
      {
        path: 'forget-password-success',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Success />
          </Suspense>
        ),
      },
      {
        path: 'verify-account',
        element: (
          <Suspense fallback={<PreLoader />}>
            <VerifyEmail />
          </Suspense>
        ),
      },
      {
        path: 'email-send',
        element: (
          <Suspense fallback={<PreLoader />}>
            <EmailSent />
          </Suspense>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <Suspense fallback={<PreLoader />}>
            <ResetPassword />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/verification',
    element: (
      <Suspense fallback={<PreLoader />}>
        <VerificationLayout />
      </Suspense>
    ),
    children: [
      {
        path: 'account-type',
        element: (
          <Suspense fallback={<PreLoader />}>
            <AccountType />
          </Suspense>
        ),
      },
      {
        path: 'terms',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Terms />
          </Suspense>
        ),
      },
      {
        path: 'google-authentication',
        element: (
          <Suspense fallback={<PreLoader />}>
            <GoogleAuth />
          </Suspense>
        ),
      },
      {
        path: 'referral-link',
        element: (
          <Suspense fallback={<PreLoader />}>
            <ReferralLink />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/',
    element: (
      <Suspense fallback={<PreLoader />}>
        <Layout />
      </Suspense>
    ),
    children: [
      {
        path: '/',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: 'wallet',
        element: (
          <Suspense fallback={<PreLoader />}>
            <WalletNew />
          </Suspense>
        ),
      },
      {
        path: 'wallet-old',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Wallet />
          </Suspense>
        ),
      },
      {
        path: 'wallet/deposit',
        element: (
          <Suspense fallback={<PreLoader />}>
            <WalletsDeposit />
          </Suspense>
        ),
      },
      {
        path: 'wallet/withdraw',
        element: (
          <Suspense fallback={<PreLoader />}>
            <WalletsWithdraw />
          </Suspense>
        ),
      },
      {
        path: 'wallet/swap',
        element: (
          <Suspense fallback={<PreLoader />}>
            <WalletsSwap />
          </Suspense>
        ),
      },
      {
        path: 'wallet/history',
        element: (
          <Suspense fallback={<PreLoader />}>
            <WalletsHistory />
          </Suspense>
        ),
      },
      {
        path: 'wallet/withdraw',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Wallet />
          </Suspense>
        ),
      },
      {
        path: 'defi-360',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Defi />
          </Suspense>
        ),
      },
      {
        path: 'defi-360/nodek',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Cloud2 />
          </Suspense>
        ),
      },
      {
        path: 'defi-360/nodek/purchase',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Cloudk2PurchasePage />
          </Suspense>
        ),
      },
      {
        path: 'defi-360/nodek/rewards-calculator',
        element: (
          <Suspense fallback={<PreLoader />}>
            <RewardsCalculator />
          </Suspense>
        ),
      },
      {
        path: 'walletk',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Walletk />
          </Suspense>
        ),
      },
      {
        path: 'xera',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Xera />
          </Suspense>
        ),
      },
      {
        path: 'wiki',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Wiki />
          </Suspense>
        ),
      },
      {
        path: 'legal',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Legal />
          </Suspense>
        ),
      },
      {
        path: 'profile',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Profile />
          </Suspense>
        ),
      },
      {
        path: 'notification',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Notifiations />
          </Suspense>
        ),
      },
      {
        path: 'newsletter',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Newsletter />
          </Suspense>
        ),
      },
      {
        path: '/community',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Community />
          </Suspense>
        ),
      },
      {
        path: '/community/super-node',
        element: (
          <Suspense fallback={<PreLoader />}>
            <SuperNode />
          </Suspense>
        ),
      },
      {
        path: '/community/friends',
        element: (
          <Suspense fallback={<PreLoader />}>
            <MyFriendsPage />
          </Suspense>
        ),
      },
      {
        path: '/global-pool',
        element: (
          <Suspense fallback={<PreLoader />}>
            <SNGP />
          </Suspense>
        ),
      },
      {
        path: '/global-pool/sngp',
        element: (
          <Suspense fallback={<PreLoader />}>
            <SNGP />
          </Suspense>
        ),
      },
      {
        path: '/global-pool/country-pools',
        element: (
          <Suspense fallback={<PreLoader />}>
            <CountryPools />
          </Suspense>
        ),
      },
      {
        path: 'external-apps',
        element: (
          <Suspense fallback={<PreLoader />}>
            <ExternalApp />
          </Suspense>
        ),
      },
      {
        path: 'support',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Support />
          </Suspense>
        ),
      },
      {
        path: '/country-pool',
        element: (
          <Suspense fallback={<PreLoader />}>
            <CountryPools />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/maintenance',
    element: (
      <Suspense fallback={<PreLoader />}>
        <Maintenance />
      </Suspense>
    ),
  },
  {
    path: 'sso',
    element: (
      <Suspense fallback={<PreLoader />}>
        <SSOAuth />
      </Suspense>
    ),
  },
  {
    path: '/impersonate',
    element: (
      <Suspense fallback={<PreLoader />}>
        <Impersonate />
      </Suspense>
    ),
  },

  {
    path: '*',
    element: (
      <Suspense fallback={<PreLoader />}>
        <PageNotFound />
      </Suspense>
    ),
  },
]);
