import { TInitialState, TActivePools, TUpComingPool } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: TInitialState = {
  activePool: [],
  scoreHistory: [],
  rewardHistory: [],
  myScore: [],
  upComingPools: [],
};

const countryPoolSlice = createSlice({
  name: 'countryPool',
  initialState,
  reducers: {
    setActivePool: (
      state: TInitialState,
      action: PayloadAction<TActivePools[]>
    ) => {
      return { ...state, activePool: action.payload };
    },
    setScoreHistory: (state: TInitialState, action: PayloadAction<any>) => {
      return { ...state, scoreHistory: action.payload };
    },
    setRewardHistory: (state: TInitialState, action: PayloadAction<any>) => {
      return { ...state, rewardHistory: action.payload };
    },
    setUpComingPools: (
      state: TInitialState,
      action: PayloadAction<TUpComingPool[]>
    ) => {
      return { ...state, upComingPools: action.payload };
    },
    setMyScore: (state: TInitialState, action: PayloadAction<any>) => {
      return { ...state, myScore: action.payload };
    },
  },
});

export const {
  setActivePool,
  setScoreHistory,
  setRewardHistory,
  setUpComingPools,
  setMyScore,
} = countryPoolSlice.actions;

export const selectPoolSlice = (state: { countryPool: TInitialState }) =>
  state.countryPool.activePool;
export const selectscoreHistorySlice = (state: {
  countryPool: TInitialState;
}) => state.countryPool.scoreHistory;
export const selectrewardHistorySlice = (state: {
  countryPool: TInitialState;
}) => state.countryPool.rewardHistory;
export const selectmyScoreSlice = (state: { countryPool: TInitialState }) =>
  state.countryPool.myScore;
export const selectupComingPoolsSlice = (state: {
  countryPool: TInitialState;
}) => state.countryPool.upComingPools;
export default countryPoolSlice.reducer;
