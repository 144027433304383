//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// COMMISSION_WALLET
// PURCHASE_WALLET
interface State {
  totalBalance: any;
  tokens: any;
  assets: any;
  growth: any;
  withdrawHistory: any;
  allTokens: any;
  allNetworks: any;
  allAddresses: any;
  transactionHistory: any;
  migrationHistory: any;
  topupHistory: any;
  walletPrioritySetting: any;
}

const initialState: State = {
  totalBalance: null,
  tokens: null,
  assets: null,
  growth: null,
  withdrawHistory: null,
  allTokens: null,
  allNetworks: null,
  allAddresses: null,
  transactionHistory: null,
  migrationHistory: null,
  topupHistory: null,
  walletPrioritySetting: null,
};

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWallet: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setAssets: (state, action: PayloadAction<any>) => {
      return { ...state, assets: action.payload };
    },
    setGrowth: (state, action: PayloadAction<any>) => {
      return { ...state, growth: action.payload };
    },
    setWithdrawHistory: (state, action: PayloadAction<any>) => {
      return { ...state, withdrawHistory: action.payload };
    },
    setTransactionHistory: (state, action: PayloadAction<any>) => {
      return { ...state, transactionHistory: action.payload };
    },
    setMigrationHistory: (state, action: PayloadAction<any>) => {
      return { ...state, migrationHistory: action.payload };
    },
    setTopupHistory: (state, action: PayloadAction<any>) => {
      return { ...state, topupHistory: action.payload };
    },
    setAllTokens: (state, action: PayloadAction<any>) => {
      return { ...state, allTokens: action.payload };
    },
    setAllNetworks: (state, action: PayloadAction<any>) => {
      return { ...state, allNetworks: action.payload };
    },
    setAllAddresses: (state, action: PayloadAction<any>) => {
      return { ...state, allAddresses: action.payload };
    },
    setWalletPrioritySetting: (state, action: PayloadAction<any>) => {
      return { ...state, walletPrioritySetting: action.payload };
    },
  },
});

export const {
  setWallet,
  setAssets,
  setGrowth,
  setWithdrawHistory,
  setAllTokens,
  setAllNetworks,
  setAllAddresses,
  setTransactionHistory,
  setMigrationHistory,
  setTopupHistory,
  setWalletPrioritySetting,
} = slice.actions;
export const selectWallet = (state: { wallet: State }) => state.wallet;
export default slice.reducer;
