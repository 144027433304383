import { TUserMembershipStatus } from "../../../utils/allConstants";

export enum OTP_METHOD {
  TOTP = 'TOTP',
  EMAIL = 'EMAIL',
}

export interface IUserLoginResponse {
  message: string;
  status: boolean;
  data: {
    loginToken: string;
    accessToken: string;
    otpMethod: OTP_METHOD;
    email: string;
  };
}

export type IUserLoginRequest = {
  username: string;
  password: string;
  captchaToken: string;
};

export interface IUserSignupResponse {
  message: string;
}

export type IUserSignupRequest = {
  email: string;
  username: string;
  password: string;
  referralUpline: string;
  country: string;
  captchaToken: string;
};

export type TUser = {
  id: string;
  username: string;
  email: string;
  referralCode: string;
  dateJoined: string;
  otpMethod: string; //
  lastPasswordChange: string;
  lastEmailChange: string;
  lastOtpMethodChange: string;
  language: string;
  profilePicture: any;
  securityCode: any;
  enrollComplete: boolean;
  accountType: any;
  termsAccepted: boolean;
  referralSubmitted: boolean;
  phone: any;
  address: any;
  city: any;
  country: string;
  zipCode: any;
  kycApproved: boolean;
  kycApprovalDate: any;
  kycAdminUpdate: boolean;
  firstName: any;
  lastName: any;
  birthDate: any;
  documentCountry: any;
  documentNumber: any;
  documentExpiryDate: any;
  companyName: any;
  companyAddress: any;
  companyRegistrationCountry: any;
  vatNumber: any;
  uboFullName: any;
  lfiKey: any;
  isActiveMembership?: boolean;
  membershipExpiry?: string;
  membershipStatus?: TUserMembershipStatus;
  isImpersonateMode?:boolean;
};

export type TReferrals = {
  referralsCount: number;
  activeReferrals: number;
  inactiveReferrals: number;

  found: boolean;
  upline: {
    id: string;
    firstName: null | string;
    lastName: null | string;
    email: string;
    username: string;
  } | null;
};

export interface IUserLoginConfirmResponse {
  expiry: string;
  token: string;
  user: TUser;
}
export interface IUserResendConfirmResponse {
  expiry: string;
  token: string;
  user: TUser;
}
export type IUserLoginConfirmRequest = {
  loginToken: string;
  otp: string;
};

export type IUserResendOTPRequest = {
  loginToken: string;
};

export interface IUserAccountTypeResponse {
  message: string;
}

export type IUserAccountTypeRequest = {
  accountType: string;
};

export interface IGetTOTPResponse {
  method: string;
  secret: string;
  url: string;
}

export interface IGetTOTPRequest {}

export interface IGetUserResponse {
  user: TUser;
}

export interface IGetUserRequest {}

export type IUserAcceptAgreementRequest = {};

export interface IUserAcceptAgreementResponse {
  message: string;
}

export type IUserReferralAgreementRequest = {
  referralUpline: string;
};

export interface IUserReferralAgreementResponse {
  referralUpline: string;
}

export interface IPostTOTPRequest {
  totpOtp: number;
}
export interface IPostTOTPResponse {
  totpOtp: string;
}

export type IResetPassword = {
  logout: boolean;
  newPassword: string;
  confirmPassword?: string | null | undefined;
  token?: string | null | undefined;
};

export type IChangePassword = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};
