//API-REQUEST
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import {
  /**/
  WALLET_DATA_WALLETS_LIST,
  WALLET_DATA_NETWORKS_LIST,
  /**/
  WALLET_DATA_WITHDRAW_HISTORY_GET,
  WALLET_DATA_TRANSFER_HISTORY_GET,
  WALLET_DATA_SWAP_REQUEST_GET,
  WALLET_DATA_DEPOSIT_HISTORY_GET,
  /**/
  WALLET_DATA_WITHDRAW_HISTORY_POST,
  WALLET_DATA_TRANSFER_HISTORY_POST,
  WALLET_DATA_SWAP_REQUEST_POST,
  WALLET_DATA_DEPOSIT_HISTORY_POST,
  /**/
  WALLET_DATA_TRX_SETTING,
  WALLET_DATA_ALL_TOKENS,
  WALLET_DATA_FAQS,
  GET_DEPOSIT_ADDRESS,
  WALLET_DATA_SETTINGS,
  VALIDATE_ADDRESS,
  VALIDATE_USER,
  WALLEK_SERVICE_URI,
  WALLEK_API_KEY,
  WALLET_GRAPH_DATA,
} from '../../utils/network/ApiEndpoints';
//REDUX-TOOLKIT
import {
  /**/
  setWalletDataWalletsList,
  setWalletDataNetworksList,
  /**/
  setWalletDataWithdrawHistory,
  setWalletDataTransferHistory,
  setWalletDataWithdrawAndTransferHistory,
  setWalletDataSwapHistory,
  setWalletDataDepositHistory,
  /**/
  setWalletDataTrxSettings,
  setWalletDataAllTokens,
  /**/
  setWalletDataWithdrawFaqs,
  setWalletDataTransferFaqs,
  setWalletDataSwapFaqs,
  setWalletDataDepositFaqs,
  setDepositAddress,
  setWithdrawSettings,
  setSwapSettings,
  setDepositSettings,
  setLoading,
  setWalletDataSlice,
  setQRLoader,
} from './walletDataSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './../index';
//AXIOS
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import ApiRequestBID from '../../utils/network/ApiRequestBID';
import { constructUrl } from '../../utils/helper';
import { TWO_ACCESS_TOKEN } from '../../utils/constants';

type paginateType = {
  page: number;
  limit: number;

  search?: string;
  query?: string | null;
  coin?: string;
  date?: string;
  status?: string;
  toDate?: any;
  fromDate?: any;
  storeRedux?: boolean;
};

export const useWalletDataAction = () => {
  const dispatch = useDispatch<AppDispatch>();

  const getListOfWalletByUserbId = async (bId: string = '') => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLEK_SERVICE_URI}/user/${bId}/wallek`,
        headers: {
          Authorization: `Bearer ${WALLEK_API_KEY}`,
        },
      })
      .then((response: AxiosResponse) => response)
      .catch((error: any) => error);
  };

  const getWalletDataWalletsList = async () => {
    dispatch(setLoading(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_DATA_WALLETS_LIST,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataWalletsList(response.data.data));
        dispatch(setLoading(false));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getWalletGraphData = async (timeline = 'monthly') => {
    dispatch(setWalletDataSlice({ graphLoading: true }));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_GRAPH_DATA,
        params: {
          timeline,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataSlice({ walletGraphData: response.data.data }));
        dispatch(setWalletDataSlice({ graphLoading: false }));
        return response;
      })
      .catch((error: any) => {
        dispatch(setWalletDataSlice({ graphLoading: false }));
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  
  const getWalletDataNetworksList = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_DATA_NETWORKS_LIST,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataNetworksList(response.data.data));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getWalletDataWithdrawHistory = async ({
    page = 1,
    limit = 10,
    query,
    coin,
    date,
  }: paginateType) => {
    dispatch(
      setWalletDataSlice({
        getWalletDataLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: constructUrl(WALLET_DATA_WITHDRAW_HISTORY_GET, {
          page,
          limit,
          query,
          coin,
          date,
        }),
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataWithdrawHistory(response.data.data));
      dispatch(
        setWalletDataSlice({
          getWalletDataLoading: false,
        })
      );
        return response;
      })

      .catch((error: any) => {
        dispatch(
          setWalletDataSlice({
            getWalletDataLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getWalletDataTransferHistory = async ({
    page = 1,
    limit = 10,
    query,
    coin,
    date,
  }: paginateType) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: constructUrl(WALLET_DATA_TRANSFER_HISTORY_GET, {
          page,
          limit,
          query,
          coin,
          date,
        }),
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataTransferHistory(response.data.data));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getWalletDataWithdrawAndTransferHistory = async ({
    page = 1,
    limit = 10,
    query,
    status,
    date,
    fromDate,
    toDate,
    storeRedux = true,
  }: paginateType) => {
    if (storeRedux) {
      dispatch(
        setWalletDataSlice({
          setWalletDataWithdrawAndTransferHistoryLoading: true,
        })
      );
    }
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_DATA_WITHDRAW_HISTORY_GET,
        params: {
          page,
          limit,
          query,
          status,
          date,
          fromDate,
          toDate,
        },
      })
      .then((response: AxiosResponse) => {
        if (storeRedux) {
          dispatch(setWalletDataWithdrawAndTransferHistory(response.data.data));
        }

        dispatch(
          setWalletDataSlice({
            setWalletDataWithdrawAndTransferHistoryLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(
          setWalletDataSlice({
            setWalletDataWithdrawAndTransferHistoryLoading: false,
          })
        );

        return error;
      });
  };
  const getWalletDataSwapHistory = async ({
    page = 1,
    limit = 10,
    query,
    fromDate,
    toDate,
    status,
    date,
    storeRedux = true,
  }: paginateType) => {
    if (storeRedux) {
      dispatch(
        setWalletDataSlice({
          getWalletDataSwapHistoryLoading: true,
        })
      );
    }
dispatch(
  setWalletDataSlice({
    getWalletDataLoading: true,
  })
);
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_SWAP_REQUEST_GET}`,
        params: {
          page,
          limit,
          query,
          fromDate,
          toDate,
        },
      })
      .then((response: AxiosResponse) => {
        if (storeRedux) {
          dispatch(setWalletDataSwapHistory(response.data.data));
        }
        dispatch(
          setWalletDataSlice({
            getWalletDataSwapHistoryLoading: false,
          })
        );
        dispatch(
          setWalletDataSlice({
            getWalletDataLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(
          setWalletDataSlice({
            getWalletDataSwapHistoryLoading: false,
          })
        );
        dispatch(
          setWalletDataSlice({
            getWalletDataLoading: false,
          })
        );
        return error;
      });
  };
  const getWalletDataDepositHistory = async ({
    page = 1,
    limit = 10,
    query,
    fromDate,
    toDate,
    status,
    date,
    storeRedux = true,
  }: paginateType) => {
    if (storeRedux) {
      dispatch(
        setWalletDataSlice({
          getWalletDataDepositHistoryLoading: true,
        })
      );
    }
dispatch(
  setWalletDataSlice({
    getWalletDataLoading: true,
  })
);
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_DEPOSIT_HISTORY_GET}`,
        params: {
          page,
          limit,
          query,
          status,
          date,
          fromDate,
          toDate,
        },
      })
      .then((response: AxiosResponse) => {
        if (storeRedux) {
          dispatch(setWalletDataDepositHistory(response.data.data));
        }
        dispatch(
          setWalletDataSlice({
            getWalletDataDepositHistoryLoading: false,
          })
        );
        dispatch(
          setWalletDataSlice({
            getWalletDataLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(
          setWalletDataSlice({
            getWalletDataDepositHistoryLoading: false,
          })
        );
             dispatch(
               setWalletDataSlice({
                 getWalletDataLoading: false,
               })
             );
        return error;
      });
  };

  const postWalletDataWithdrawHistory = async (data: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: WALLET_DATA_WITHDRAW_HISTORY_POST,
        data,
      })
      .then((response: AxiosResponse) => response)
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const postWalletDataTransferHistory = async (data: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: WALLET_DATA_TRANSFER_HISTORY_POST,
        data,
      })
      .then((response: AxiosResponse) => response)
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const postWalletSwap = async (data: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: WALLET_DATA_SWAP_REQUEST_POST,
        data,
      })
      .then((response: AxiosResponse) => response)
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        throw error;
      });
  };
  const postWalletDataDepositHistory = async (data: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: WALLET_DATA_DEPOSIT_HISTORY_POST,
        data,
      })
      .then((response: AxiosResponse) => response)
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getWalletDataTrxSettingsByTokenId = async ({
    id,
    type,
  }: {
    id: string;
    type: string;
  }) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `/tokens/${id}${WALLET_DATA_TRX_SETTING}?type=${type}`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataTrxSettings(response.data.data));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getWalletDataAllTokens = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_DATA_ALL_TOKENS,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataAllTokens(response.data.data));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getWalletDataWithdrawFaqs = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_FAQS}/withdraw`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataWithdrawFaqs(response.data));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getWalletDataTransferFaqs = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_FAQS}/transfer`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataTransferFaqs(response.data));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getWalletDataSwapFaqs = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_FAQS}/swap`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataSwapFaqs(response.data));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getWalletDataDepositFaqs = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_FAQS}/deposit`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataDepositFaqs(response.data));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getDepositAddress = async ({
    token,
    network,
  }: {
    token: string;
    network: string;
  }) => {
    dispatch(setQRLoader(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${GET_DEPOSIT_ADDRESS}?token=${token}&network=${network}`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setDepositAddress(response.data.data));
        dispatch(setQRLoader(false));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getWalletDataWithdrawSettings = async (type: string) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_SETTINGS}/withdraw/settings?type=${type}`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWithdrawSettings(response.data.data));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getWalletDataSwapSettings = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_SETTINGS}/swap/settings`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setSwapSettings(response.data.data));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getWalletDataDepositSettings = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_SETTINGS}/deposit/settings`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setDepositSettings(response.data.data));
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getValidateAddress = async ({
    address,
    network,
  }: {
    address: string;
    network: string;
  }) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${VALIDATE_ADDRESS}?address=${address}&network=${network}`,
      })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Something went wrong');

        return error;
      });
  };

  const getValidateUser = async (userID: string) => {
    return await ApiRequestBID()
      .request({
        method: 'GET',
        url: `${VALIDATE_USER}/${userID}`,
        headers: {
          Authorization: TWO_ACCESS_TOKEN,
        },
      })
      .then((response: any) => {
        return response;
      })
      .catch((error: Error) => {
        toast.error(error.message || 'User Not Found');

        return error;
      });
  };

  return {
    getListOfWalletByUserbId,
    /**/
    getWalletDataWalletsList,
    getWalletGraphData,
    getWalletDataNetworksList,
    /**/
    getWalletDataWithdrawHistory,
    getWalletDataTransferHistory,
    getWalletDataWithdrawAndTransferHistory,
    getWalletDataSwapHistory,
    getWalletDataDepositHistory,
    /**/
    postWalletDataWithdrawHistory,
    postWalletDataTransferHistory,
    postWalletSwap,
    postWalletDataDepositHistory,
    /**/
    getWalletDataTrxSettingsByTokenId,
    getWalletDataAllTokens,
    /**/
    getWalletDataWithdrawFaqs,
    getWalletDataTransferFaqs,
    getWalletDataSwapFaqs,
    getWalletDataDepositFaqs,
    /**/
    getDepositAddress,
    /**/
    getWalletDataWithdrawSettings,
    getWalletDataSwapSettings,
    getWalletDataDepositSettings,
    /**/
    getValidateAddress,
    getValidateUser,
  };
};
