import React from 'react';
import './PurchaseCalculatorSlider.css';
type Props = {
  currentValue?: number;
  getCurrentValue?: (arg: number) => void;
  minValue?: number;
  maxValue?: number;
  step?:number
};
const PurchaseCalculatorSlider = ({
  minValue = 1,
  maxValue = 999999999,
  getCurrentValue,
  currentValue = 1,
  step=1
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (getCurrentValue) {
      getCurrentValue(Number(event.target.value));
    }
  };
  React.useEffect(() => {
    document.documentElement.style.setProperty(
      '--slider-value',
      `${(currentValue / maxValue) * 100}%`
    );
  }, [minValue, maxValue, currentValue]);
  return (
    <div className="flex w-full flex-col cursor-pointer">
      <div className="rounded-full border border-borderBlue relative h-4">
        <input
          type="range"
          className="slider absolute top-1 left-1 w-[calc(100%-.5rem)] appearance-none focus:outline-black bg-primary rounded-full cursor-pointer"
          min={minValue}
          max={maxValue}
          value={currentValue}
          step={step}
          onChange={handleChange}
        />
      </div>
      {/* <div className="flex items-center justify-between px-2 text-center text-borderBlue">
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
                <span className="text-[6px] font-bold opacity-50" aria-hidden="true">|</span>
            </div>
            <div className='flex items-center justify-between px-3 text-center text-borderBlue'>
                <span className="w-0 -translate-x-1 text-xs " aria-hidden="true">0%</span>
                <span className="w-0 -translate-x-1 text-xs" aria-hidden="true">20%</span>
                <span className="w-0 -translate-x-1 text-xs" aria-hidden="true">40%</span>
                <span className="w-0 -translate-x-1 text-xs" aria-hidden="true">60%</span>
                <span className="w-0 -translate-x-1 text-xs" aria-hidden="true">80%</span>
                <span className="w-0 -translate-x-2 text-xs" aria-hidden="true">100%</span>
            </div> */}
    </div>
  );
};

export default PurchaseCalculatorSlider;
