type Props = {};

const RenderRewardsMessage = (props: Props) => {
  return (
    <div className="col-span-2 w-full flex items-center justify-between flex-col md:flex-row space-y-2 md:space-y-0">
      <div className="md:flex md:items-center md:space-x-2.5 text-sm">
      Rewards shown here are indicative and not guaranteed. Actual rewards may vary based on market conditions and participation. Conduct your own research before making any decisions.
      </div>
    </div>
  );
};

export default RenderRewardsMessage;
