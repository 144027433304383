/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PreLoader from '../../../components/Global/PreLoader';
import { setLogin, setLoginToken } from '../../../store/user/userSlice';
import ApiRequestHomnifi from '../../../utils/network/ApiRequestHomnifi';
import { PAGES } from '../../../utils/routers';
import { API_INSTANCE_HOMNIFI } from '../../../utils/network/ApiEndpoints';

const Impersonate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    handleImpersonation();
  }, []);

  const handleImpersonation = async () => {
    if (searchParams.get('token')) {
      try {
        const response = await ApiRequestHomnifi().request({
          method: 'GET',
          headers: {
            Authorization: `Bearer ${searchParams.get('token')}`,
          },
          url: `/impersonate`,
        });
        if (response.data) {
          dispatch(
            setLogin({
              loginToken: response.data.twoAcessUserToken,
              accessToken: response.data.homnifiAccessToken,
            })
          );
          dispatch(
            setLoginToken({
              user: response.data.user,
            })
          );
          navigate(PAGES.DASHBOARD, { replace: true });
        }
      } catch (error) {
        toast.error('Unauthorized');
        navigate(PAGES.LOGIN, { replace: true });
      }
    } else {
      toast.error('Unauthorized');
      navigate(PAGES.LOGIN, { replace: true });
    }
  };

  return <div className="h-screen">
    <PreLoader />
  </div>;
};

export default Impersonate;
