import {
  facebook,
  linkedin,
  share,
  telegram,
  twitter,
} from '../images/Socials';

const environment = process.env;

export const MEMBERSHIP_DATE = '1722423559662';


export const PLATFORM_NAME = environment.REACT_APP_PLATFORM_NAME;
export const PLATFORM_ENVIRONMENT = environment.REACT_APP_ENVIRONMENT;
export const SOCKET_BASE_URL = `${environment.REACT_APP_API_INSTANCE_HOMNIFI}homnifi`;
export const TWO_ACCESS_TOKEN = environment.REACT_APP_TWO_ACCESS_TOKEN;
export const HCATCHA_SITEKEY = environment.REACT_APP_HCAPTCHA_SITEKEY;
export const HASH_ITHDRAW_LINK = environment.REACT_APP_HASH_WITHDRAW_LINK;

export const TOASTER_AUTOCLOSE_TIME =
  environment.REACT_APP_TOASTER_AUTOCLOSE_TIME;

export const ACCESS_TOKEN = 'accessToken';
export const LOGIN_TOKEN = 'loginToken';

export const SOCIALS = [
  {
    title: 'Telegram',
    icon: telegram,
    link: environment.REACT_APP_TELEGRAM,
    color: 'telegram-bg',
  },
  {
    title: 'Linkedin',
    icon: linkedin,
    link: environment.REACT_APP_LINKEDIN,
    color: 'linkedin-bg',
  },
  {
    title: 'TwitterX',
    icon: twitter,
    link: environment.REACT_APP_X,
    color: 'twitter-bg',
  },
  {
    title: 'Facebook',
    icon: facebook,
    link: environment.REACT_APP_FACEBOOK,
    color: 'facebook-bg',
  },
  // {
  //   title: 'Youtube',
  //   icon: youtube,
  //   link: environment.REACT_APP_YOUTUBE,
  //   color: 'youtube-bg',
  // },
  // {
  //   title: 'Instagram',
  //   icon: instagram,
  //   link: environment.REACT_APP_INSTAGRAM,
  //   color: 'instagram-bg',
  // },
  {
    title: 'share',
    icon: share,
    link: 'share',
    color: 'share-bg',
  },
];

export const FOOTER_SOCIALS = {
  telegram: environment.REACT_APP_TELEGRAM,
  telegramChatRoom: environment.REACT_APP_TELEGRAM_CHAT_ROOM,
  linkedIn: environment.REACT_APP_LINKEDIN,
  twitterX: environment.REACT_APP_X,
  facebook: environment.REACT_APP_FACEBOOK,
  youtube: environment.REACT_APP_YOUTUBE,
  instagram: environment.REACT_APP_INSTAGRAM,
};

export const LINKS = {
  LEGAL: environment.REACT_APP_LEGAL_LINK,
  WIKI: environment.REACT_APP_WIKI_LINK,
  HOW_IT_WORKS: environment.REACT_APP_HOW_IT_WORKS,
  SUPPORT_URL: environment.REACT_APP_SUPPORT_URL,
};

export const DASHBOARD_REFERRAL = `${environment.REACT_APP_WEBSITE_DOMAIN}auth/signup?ref=`;

export const SHOP_CONSTANT = {
  externalId: environment.REACT_APP_PLATFORM_ID,
};

export const QUANTWISE_CONSTANT = {
  externalId: environment.REACT_APP_QUANTWISE_ID,
};

export const WALLEK_CONSTANT = {
  externalId: environment.REACT_APP_WALLEK_ID,
};

export const RAMPSTARTER_CONSTANT = {
  externalId: environment.REACT_APP_RAMPSTARTER_ID,
};

// export const RAMPSTARTER_CONSTANT_2 = {
//   externalId: environment.REACT_APP_RAMPSTARTER_TWO_ID,
// };

export const XERA_CONSTANT = {
  externalId: environment.REACT_APP_XERA_ID,
};

export const CLOUDK_CONSTANT = {
  externalId: environment.REACT_APP_CLOUDK_ID,
};
