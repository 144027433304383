import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useCopyTextHook from '../../hooks/useCopyTextHook';
import useUserCheck from '../../hooks/useUserCheck';
import { profileImg } from '../../images';
import { copy, tick } from '../../images/others';
import { VerifiedIcon } from '../../images/svgs';
import { setUserState } from '../../store/user/userSlice';
import LinearProgressBar from '../Progress';

const ProfilePopOver = ({
  setProfile,
  setBusinessCard,
  setIsLogoutClicked,
  status,
  isBoolean,
}: {
  setProfile: (e: boolean) => void;
  setBusinessCard: (e: boolean) => void;
  setIsLogoutClicked: any;
  status: boolean | null ,
  isBoolean : (e:any)=>boolean
}) => {
  const { user, profileCompletePercentage } = useUserCheck();
  const { writeClipboardText } = useCopyTextHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = React.useState(false);
  const location = useLocation();

  const CheckProfile =
    user?.firstName ||
    user?.lastName ||
    user?.city ||
    user?.address ||
    user?.phone;

  const handleCopy = () => {
    writeClipboardText(user?.id || '');
    toast.success('ID Copied!');
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);

    // navigator.clipboard.writeText(user?.id || '').then(() => {
    //   setIsCopied(true);

    //   setTimeout(() => {
    //     setIsCopied(false);
    //   }, 5000);
    // });
  };

  return (
    <div className="flex flex-col absolute top-2 right-0 w-96 bg-white border border-borderLightGray shadow-lg h-auto rounded-lg">
      <div className="w-full flex justify-start gap-2 items-start p-4">
        <div>
          <div className="w-16 h-16 relative">
            {isBoolean(status) &&
              location.pathname === '/community/super-node' && (
                <span className="absolute top-[1px] right-[11px] flex gap-2 h-2 w-2">
                  <span
                    className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${
                      status ? 'bg-primaryGreen' : 'bg-primaryRed'
                    }`}
                  ></span>
                  <span
                    className={`relative inline-flex rounded-full h-2 w-2 ${
                      status ? 'bg-primaryGreen' : 'bg-primaryRed'
                    }`}
                  ></span>
                </span>
              )}
            <img
              src={user?.profilePicture ? user?.profilePicture : profileImg}
              alt="Profile"
              className="w-full h-full rounded-full object-cover"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-col gap-0">
            <div className="flex gap-2">
              <Link
                to="/profile"
                className="text-xs text-primaryGrey flex items-center gap-2"
                // onClick={() => setProfile(false)}
              >
                ID: {user?.id && user?.id}
              </Link>
              <img
                src={isCopied ? tick : copy}
                alt="icon"
                className="cursor-pointer w-2.5"
                onClick={handleCopy}
              />
            </div>
            <Link
              to="/profile"
              className="text-lg font-medium leading-0 flex items-center gap-2"
              onClick={() => setProfile(false)}
            >
              @{user?.username}
              {user?.kycApproved && <VerifiedIcon />}
            </Link>

            <Link
              to="/profile"
              className="text-xs text-primaryGrey"
              onClick={() => {
                setProfile(false);
              }}
            >
              {user?.email}
            </Link>
          </div>

          <div className="w-full bg-borderLightGray rounded-full overflow-hidden">
            {/* <div className="w-1/2 bg-primaryGreen h-full" /> */}
            <LinearProgressBar value={profileCompletePercentage} />
          </div>

          <div className="flex gap-2">
            {!CheckProfile && (
              <Link
                to="/profile"
                className="bg-primary text-white rounded-full px-4 py-1 flex items-center text-xs"
                onClick={() => {
                  setProfile(false);
                  dispatch(setUserState({ profileActiveTab: 'PROFILE' }));
                }}
              >
                Complete Profile
              </Link>
            )}

            {(!user?.kycApproved || !user.isActiveMembership) && (
              <Link
                to="/profile"
                className="flex items-center bg-white text-primaryGrey border border-borderLightGray hover:bg-primary hover:text-white transition-all rounded-full px-4 py-1 text-xs"
                onClick={() => {
                  setProfile(false);
                  dispatch(setUserState({ profileActiveTab: 'KYC' }));
                }}
              >
                {!user?.isActiveMembership
                  ? `Buy Membership`
                  : `Complete ${user?.accountType === 'PERSONAL' ? 'KYC' : 'KYB'}`}
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-0">
        <button
          onClick={() => {
            setProfile(false);
            navigate('/profile');
          }}
          className="font-medium flex items-center gap-1 text-base hover:text-primary transition-all py-3 px-4"
        >
          Profile Settings
        </button>
        <hr />
        {/* <button
          onClick={() => {
            setProfile(false);
            setBusinessCard(true);
          }}
          className="font-medium flex items-center gap-1 text-base hover:text-primary transition-all py-3 px-4"
        >
          Generate Business Card QR
        </button>
        <hr /> */}
        <Link
          to="/support"
          onClick={() => setProfile(false)}
          className="font-medium flex items-center gap-1 text-base hover:text-primary transition-all py-3 px-4"
        >
          Customer Support
        </Link>
        <hr />
        <button
          onClick={() => setIsLogoutClicked(true)}
          className="font-medium flex items-center gap-1 text-base text-primaryGrey hover:text-primary transition-all py-3 px-4"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default ProfilePopOver;
