import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  selectNotification,
  setIsNotificationOpen,
} from '../store/notifications/notificationsSlice';
import {
  selectNewsletter,
  setIsNewsletterOpen,
} from '../store/newsLetter/newsLetterSlice';

function useNotificationToggler() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const size = useWindowSize();

  const { isNewsletterOpen } = useSelector(selectNewsletter);
  const { isNotificationOpen } = useSelector(selectNotification);

  const notificationPopupToggler = () => {
    if (isNotificationOpen || location.pathname === '/notification') {
      if (size.width > 0) {
        if (size.width <= 768 && isNotificationOpen) {
          dispatch(setIsNotificationOpen(false));
          navigate('/notification');
        } else {
          if (location.pathname === '/notification' && size.width >= 768) {
            dispatch(setIsNotificationOpen(true));
            navigate('/');
          }
        }
      }
    }
  };

  const newsLetterPopupToggler = () => {
    if (isNewsletterOpen || location.pathname === '/newsletter') {
      if (size.width > 0) {
        if (size.width <= 768 && isNewsletterOpen) {
          dispatch(setIsNewsletterOpen(false));
          navigate('/newsletter');
        } else {
          if (location.pathname === '/newsletter' && size.width >= 768) {
            dispatch(setIsNewsletterOpen(true));
            navigate('/');
          }
        }
      }
    }
  };

  return {
    notificationPopupToggler,
    newsLetterPopupToggler,
  };
}

export default useNotificationToggler;
